'use client'
import { TWButton } from '@/components/tailwind/Button'
import TWInput from '@/components/tailwind/Input'
import { PasswordStrength } from '@/components/tailwind/PasswordStrength'
import { ToastifyContext } from '@/context'
import { useRecaptcha } from '@/hooks/captcha'
import { usePasswordStrength } from '@/hooks/forms/usePasswordStrength'
import { useConstruct, useFormStates } from '@/hooks/utils/useForm'
import { types } from '@/types'
import { Icons, inputsTypes, registerIn, validateForm, valSamePass } from '@/utils/functions/form'
import { useRouter } from 'next/navigation'
import { useContext, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

const RegisterForm = ({ hostname, rtnRoute, texts }) => {
  const [ visibilityPass, setVisibilityPass ] = useState(false)
  const [ samePass, setSamePass ] = useState(``)
  const [ registerErr, setRegisterErr ] = useState({})
  const [ passStrength, handleStrength ] = usePasswordStrength()
  const [ visibilityRecoverPass, setVisibilityRecoverPass ] = useState(false)
  const { colors } = hostname
  const valueForm = useConstruct(texts.register_fields)
  const { charge, callReCaptcha } = useRecaptcha()
  const { setErrorToastify } = useContext(ToastifyContext)
  const router = useRouter()

  const {
    register,
    getValues,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm()

  const { dis, setDis, checkedCaptcha, setCheckedCaptcha, checked, handleCheck } = useFormStates()

  const handleChange = e => {
    handleStrength(e)
    return e.target.value
  }

  const onSubmit = async e => {
    e?.preventDefault()
    await clearErrors()
    const valValues = await !validateForm(
      texts.register_fields, getValues(), setError, texts.form_errors, true
    )
    const sameFlag = await valSamePass(getValues, setError, clearErrors)
    if (!sameFlag) {
      await setSamePass(texts.password_match)
    } else {
      await setSamePass(``)
      if (valValues) {
        const data = {}
        Object.entries(getValues()).map(x => (data[x[0]] = x[1].trim()))
        const dataRegister = {
          ...data,
          discount_tariff: hostname?.discount_tariff_id,
          platformUser: hostname?.id,
          mailing: checked ? 0 : 1,
          lang: hostname?.lang,
        }
        if (charge) {
          await callReCaptcha({
            type: types.preferences.register,
            setCheckedCaptcha,
            setDis,
            texts,
            data: dataRegister,
            referer: rtnRoute,
            hostname,
          })
        } else if (checkedCaptcha) {
          await registerIn(
            setDis, dataRegister, router, rtnRoute, texts, setErrorToastify, null, hostname
          )
        }
      }
    }
  }

  useMemo(() => setRegisterErr(errors), [errors])
  return (
    <form name="register-form" className="grid grid-cols-1 tablet:grid-cols-2 gap-2 w-full">
      {valueForm?.map((x, i) => {
        const { inputType, name } = inputsTypes(
          x.type, x.inputType, x.name, visibilityPass, visibilityRecoverPass
        )
        const error = [ types.input.pass, types.input.recoverPass ].includes(x.type) ? registerErr[x.type]?.message.length ? registerErr[x.type]?.message : samePass : registerErr[x.type]?.message
        return <span key={`login_${x.type}`} className={`${x.fullWidth ? `tablet:col-span-2` : `col-span-1`}`}>
          <TWInput
            {...{ colors }}
            {...register(x.type, {
              required: x.required,
              onChange: handleChange,
            })}
            error={error}
            starticon={Icons(x.type, x.type === types.input.pass ? visibilityPass : visibilityRecoverPass)}
            clickicon={() => x.type === types.input.pass ? setVisibilityPass(!visibilityPass) : setVisibilityRecoverPass(!visibilityRecoverPass)}
            placeholder={x.placeholder}
            type={inputType}
            required={x.required}/>
          { x.type === types.input.pass && <PasswordStrength {...{ passStrength }} texts={texts} /> }
        </span>
      })}

      {/* <p className="text-error tablet:col-span-2 tablet:text-center">{samePass}</p> */}
      <div className="flex tablet:col-span-2 px-4">
        <input id="rgpd" name="rgpd" type="checkbox" checked={checked} onChange={handleCheck} />
        <label htmlFor="rgpd" className="text-xs ml-2 leading-3">{texts.rgpd}</label>
      </div>
      <p className="text-xs text-neutral-600">{texts.required_fields}</p>
      <TWButton {...{ colors }} className="w-full tablet:col-span-2" onClick={onSubmit}>
        {texts.btn_register}
      </TWButton>
    </form>
  )
}
export default RegisterForm
