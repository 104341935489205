'use client'

import { TWButton } from "@/components/tailwind/Button"
import { LoadingContext } from "@/context/tools/loading"
import { ToolsContext } from "@/context/tools/tools"
import { useEffectOnce } from "@/hooks/utils/useEffectOnce"
import { useSession } from "next-auth/react"
import { redirect } from "next/navigation"
import { useContext, useMemo, useState } from "react"
import LoginForm from "./LoginForm"
import LostPassForm from "./LostPassForm"
import RegisterForm from "./RegisterForm"

export const Login = ({ hostname, pageParams }) => {
  const { tools: { texts }} = useContext(ToolsContext)
  const { modules } = useContext(LoadingContext)
  const { data: login } = useSession()

  useEffectOnce(() => {
    modules.handleLinkLoading(false)
    login && redirect(process.env.NEXT_PUBLIC_ACCOUNT)
  })
  const loginTexts = useMemo(() => texts.login_page, [texts])
  const [ recoverPass, setRecoverPass ] = useState(false)

  const [ showLogin, setShowLogin ] = useState(!pageParams?.new?.includes(`true`))
  const rtnRoute = process.env.NEXT_PUBLIC_CART.includes(pageParams?.from) ? process.env.NEXT_PUBLIC_CART : process.env.NEXT_PUBLIC_ACCOUNT
  const { colors } = hostname
  return !!loginTexts && (
    <div>
      <div className="flex flex-col 1200:flex-row justify-evenly items-start divide-y-2 1200:divide-x-2 1200:divide-y-0 1200:py-8">
        <div id="login-form" className="flex w-full items-center justify-center 1200:justify-start p-4 1200:p-0 1200:px-8">
          {showLogin ?
            !recoverPass ? <LoginForm {...{ hostname, setRecoverPass, rtnRoute, texts: loginTexts }}/> :
              <LostPassForm {...{ hostname, setRecoverPass, texts: loginTexts }}/> :
            <TWButton {...{ colors }} bg={colors.dark} className="w-full tablet:col-span-2" onClick={() => setShowLogin(true)}>
              {loginTexts.login_title}
            </TWButton>
          }
        </div>
        <div id="Register-form" className={`flex w-full justify-center 1200:justify-end p-4 1200:p-0 1200:px-8 ${showLogin ? `1200:min-h-[250px] items-start` : `items-center `}`}>
          {!showLogin ?
            <div className="max-w-3xl 1200:max-w-2xl">
              <h1 className="text-left">{loginTexts.register_title}</h1>
              <p className="text-left mt-2 mb-4">{loginTexts.register_subtitle}</p>
              <RegisterForm {...{ hostname, rtnRoute, texts: loginTexts }}/>
            </div> :
            <TWButton {...{ colors }} bg={colors.dark} className="w-full tablet:col-span-2" onClick={() => setShowLogin(false)}>
              {loginTexts.btn_create_account}
            </TWButton>
          }
        </div>
      </div>
      <div className="grid p-8 gap-2">
        {loginTexts.rgpd_1.map((text, index) => (
          <p key={`rgpd_${index}`} className="text-xs text-neutral-400 text-justify">{text}</p>
        ))}
      </div>
    </div>
  )
}
