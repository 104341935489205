'use client'
import { TWButton } from '@/components/tailwind/Button'
import TWInput from '@/components/tailwind/Input'
import { CartContext, ToastifyContext } from '@/context'
import { useRecaptcha } from '@/hooks/captcha'
import { useConstruct, useFormStates } from '@/hooks/utils/useForm'
import { types } from '@/types'
import { Icons, inputsTypes, loginIn, validateForm } from '@/utils/functions/form'
import { useRouter } from 'next/navigation'
import { useContext, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

const LoginForm = ({ hostname, setRecoverPass, rtnRoute, texts }) => {
  const { cart: { basket }} = useContext(CartContext)
  const [ visibilityPass, setVisibilityPass ] = useState(false)
  const [ loginErr, setLoginErr ] = useState({})
  const [ visibilityRecoverPass, setVisibilityRecoverPass ] = useState(false)
  const { colors } = hostname
  const valueForm = useConstruct(texts.login_fields)
  const { charge, captcha, callReCaptcha } = useRecaptcha()
  const { setErrorToastify } = useContext(ToastifyContext)
  const router = useRouter()
  const {
    register,
    getValues,
    setError,
    clearErrors,
    setFocus,
    watch,
    formState: { errors },
  } = useForm()
  const { dis, setDis, checkedCaptcha, setCheckedCaptcha } = useFormStates()

  const onSubmit = async e => {
    e?.preventDefault()
    await clearErrors()
    const valValues = await !validateForm(
      texts.login_fields, getValues(), setError, texts.form_errors
    )
    await setDis(valValues)
    if (valValues) {
      const data = {}
      Object.entries(getValues()).map(x => (data[x[0]] = x[1].trim()))

      const dataLogin = {
        username: data.email,
        password: data.password,
      }
      if (charge) {
        await callReCaptcha({
          type: types.preferences.login,
          setCheckedCaptcha,
          setDis,
          texts,
          data: dataLogin,
          referer: rtnRoute,
          hostname,
        })
      } else if (checkedCaptcha) {
        await loginIn(
          setDis, dataLogin, router, rtnRoute, texts, setErrorToastify, hostname
        )
      }
    }
  }

  useMemo(() => setLoginErr(errors), [errors])

  return (<div className="max-w-3xl 1200:max-w-2xl">
    <h1 className="text-left">{texts.login_title}</h1>
    <p className="text-left mt-2 mb-4"> {texts.login_subtitle}</p>
    <form name="login-form" className="grid grid-cols-1 tablet:grid-cols-2 gap-2 w-full">
      {valueForm?.map((x, i) => {
        const { inputType, name } = inputsTypes(
          x.type, x.inputType, x.name, visibilityPass, visibilityRecoverPass
        )
        return <TWInput
          key={`login_${x.type}`} {...{ colors }}
          {...register(x.type, {
            required: x.required,
            onChange: e => e.target.value,
          })}
          error={loginErr[x.type]?.message}
          className={`${x.fullWidth ? `tablet:col-span-2` : `col-span-1`}`}
          starticon={Icons(x.type, x.type === types.input.pass ? visibilityPass : visibilityRecoverPass)}
          clickicon={() => x.type === types.input.pass ? setVisibilityPass(!visibilityPass) : setVisibilityRecoverPass(!visibilityRecoverPass)}
          placeholder={x.placeholder}
          type={inputType}
          required={x.required}/>
      })}
      <p className="text-xs text-neutral-600 tablet:col-span-2">{texts.required_fields}</p>
      <TWButton {...{ colors }} className="w-full tablet:col-span-2" onClick={onSubmit}>
        {texts.btn_login}
      </TWButton>

      {/* {captcha && (
        <div className="flex justify-center">
        <CheckRecaptcha {...{ checkedCaptcha, setCheckedCaptcha, robot: texts.web.login?.section.robot, colors }} />
        </div>
        )} */}
      <span className="flex w-full items-baseline tablet:col-span-2">
        <p>{texts.password_text}</p>
        <p className="ml-2 underline cursor-pointer" onClick={() => setRecoverPass(true)}>{texts.password_link}</p>
      </span>
    </form>
  </div>
  )
}

export default LoginForm
