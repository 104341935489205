'use client'
import { TWButton } from '@/components/tailwind/Button'
import TWInput from '@/components/tailwind/Input'
import { ToastifyContext } from '@/context'
import { useRecaptcha } from '@/hooks/captcha'
import { useConstruct, useFormStates } from '@/hooks/utils/useForm'
import { types } from '@/types'
import { Icons, inputsTypes, recoverPassIn, validateForm } from '@/utils/functions/form'
import { forms } from '@/utils/global'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'

const LostPassForm = ({ hostname, setRecoverPass, texts }) => {
  const [ recoverSent, setRecoverSent ] = useState(false)
  const [ visibilityPass, setVisibilityPass ] = useState(false)
  const [ visibilityRecoverPass, setVisibilityRecoverPass ] = useState(false)
  const { colors } = hostname
  const { password_success, recover_password_fields, password_title, required_fields, return_login, password_link, password_btn } = texts
  const valueForm = useConstruct(recover_password_fields)
  const { charge, callReCaptcha } = useRecaptcha()
  const { setErrorToastify } = useContext(ToastifyContext)
  const {
    register,
    getValues,
    setError,
    formState: { errors },
  } = useForm()
  const { dis, setDis, checkedCaptcha, setCheckedCaptcha } = useFormStates()

  const onSubmit = async e => {
    e.preventDefault()
    const valValues = await !validateForm(
      forms.RECOVERPASS, getValues(), setError, texts
    )
    await setDis(valValues)
    if (valValues) {
      const data = {}
      Object.entries(getValues()).map(x => (data[x[0]] = x[1].trim()))
      const dataRecoverPass = {
        ...data,
        lang: hostname.lang,
        domain: hostname.domain,
      }
      if (charge) {
        await callReCaptcha({
          type: types.preferences.recoverPass,
          setCheckedCaptcha,
          setDis,
          texts,
          data: dataRecoverPass,
          setShow: setRecoverSent,
        })
      } else if (checkedCaptcha) {
        await recoverPassIn(
          setDis, dataRecoverPass, texts, setErrorToastify, setRecoverSent
        )
      }
    }
  }

  return (
    <div className="w-full">
      <h1 className="text-left mb-4">{password_title}</h1>
      {recoverSent ? <div className="flex items-baseline">
        <p className="text-left">{password_success.sent}</p>
        <p className="ml-1 text-blue-500 font-semibold">{getValues(types.input.email)}</p>
      </div> :
        <form name="login-form" className="grid grid-cols-1 tablet:grid-cols-2 gap-2 w-full">
          {valueForm?.map((x, i) => {
            const { inputType, name } = inputsTypes(
              x.type, x.inputType, x.name, visibilityPass, visibilityRecoverPass
            )
            return <TWInput
              key={`login_${x.type}`} {...{ colors }}
              {...register(x.type, {
                required: x.required,
                onChange: e => e.target.value,
              })}
              error={errors[x.type]?.message}
              className={`${x.fullWidth ? `tablet:col-span-2` : `col-span-1`}`}
              starticon={Icons(x.type, x.type === types.input.pass ? visibilityPass : visibilityRecoverPass)}
              clickicon={() => x.type === types.input.pass ? setVisibilityPass(!visibilityPass) : setVisibilityRecoverPass(!visibilityRecoverPass)}
              placeholder={x.placeholder}
              type={inputType}
              required={x.required}/>
          })}
          <p className="text-xs text-neutral-600 tablet:col-span-2">{required_fields}</p>
          <TWButton {...{ colors }} className="w-full tablet:col-span-2" onClick={onSubmit}>
            {password_btn}
          </TWButton>
        </form>}
      <span className="flex w-full items-baseline tablet:col-span-2 mt-2">
        <p>{return_login}</p>
        <p className="ml-2 underline cursor-pointer" onClick={() => setRecoverPass(false)}>{password_link}</p>
      </span>
    </div>
  )
}

export default LostPassForm
